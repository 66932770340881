
export function function__misc__get_guid()
{
    return function__random__s4() + function__random__s4() +
        '-' + function__random__s4() + '-' + function__random__s4() +
        '-' + function__random__s4() + '-' + function__random__s4() +
        function__random__s4() + function__random__s4();
}


export function function__random__s4()
{
    return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
}