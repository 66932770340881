

//aws
//declare var Foo;
var variables = {
    aws__region: "us-east-1",
    aws__s3__max_file_size: (20 * (1024 * 1024)),
    aws__s3__uploads_bucket: "convertimages.net",
    aws__cognito_pool_id: "us-east-1:737fbb89-4927-4fc3-ae11-73ef553308ac",

    urls__user_files_base: "https://files.convertimages.net/",
    urls__user_files_uploads_base: "https://files.convertimages.net/uploads/",
    urls__user_files_downloads_base: "https://files.convertimages.net/downloads/"
};

export default variables