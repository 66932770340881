import React from 'react';
//import logo from './logo.svg';
import './css/site.scss';
import './css/errors.scss';
import './css/home.scss';
import './css/home-upload-file.scss';
import './css/home-uploaded-file.scss';
import './css/home-compression-quality.scss';
import './css/home-resize-options.scss';
import './css/output-file-types.scss';
import './css/home-process.scss';
import './css/home-processing.scss';
import './css/home-success.scss';
import './css/upload-progress.scss';
import './css/header.scss';
import './css/footer.scss';
import './css/spinner.scss';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Home from './Views/Home';
import Privacy from './Views/Privacy';
import Header from './Components/Header';
import Footer from './Components/Footer';

function App()
{        
    return (
        <div className="App">
            <Header />
            
            <div id="container-e051b29361f2ae78a4c93f0e9c0cf22a"></div>

            <BrowserRouter>
                <div>
                    <Switch>
                        <Route exact={true} path='/Privacy' render={() => (
                            <div className="view-privacy">
                                <Privacy />
                            </div>
                        )} /> 
                        <Route exact={true} path='/Testing' render={() => (
                            <div className="view-home">
                                <Home />
                            </div>
                        )} />                        
                        <Route path='*' render={() => (
                            <div className="view-home">                                
                                <Home />
                            </div>
                        )} />
                    </Switch>
                </div>
            </BrowserRouter>

            <Footer />
        </div>
    );
}

export default App;
