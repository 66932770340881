import React, { Component } from 'react';


export default class Footer extends Component
{
    state = {
    }
    render()
    {
        return (
            <footer className="row justify-content-md-center">                
                <div className="col-12 col-md-auto">
                    <a href="/">ConvertImages.net ©2020</a>
                </div>                
                <div className="col-12 col-md-auto">
                    <a href="/privacy/">Privacy Policy</a>
                </div>                
            </footer>
        )
    }
}