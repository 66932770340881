import React, { Component } from 'react';

export default class Header extends Component
{
    state = {
    }
    render()
    {
        return (
            <header>
                <h1 title="convert and resize images"><a title="convert and resize images" className="site-name" href="/">Convert Images</a></h1>
                <h2>Upload - Resize - Compress - Convert</h2>
                <h3>ConvertImages.net is an easy to use tool allowing you to compress, resize, and convert images. Import all file types.
                    Export PNGs, JPGs, WEBPs, TIFFs, and ICOs.
                </h3>
            </header>
        )
    }
}