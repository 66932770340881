//https://material-ui.com/

import React, { Component } from 'react';
import SiteVariables from "../js/site-variables";
import { function__misc__get_guid } from "../js/misc-functions";
import Slider from '@material-ui/core/Slider';

const $ = window.$;
const AWS = window.AWS;
const cv_rnd_01 = window.cv_rnd_01;

export default class UploadImage extends Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            step: "upload", //upload, uploading, review, process
            show_spinner: false,
            uploaded_image_url: "",
            uploaded_image_filename: "",
            uploaded_image_width: 0,
            uploaded_image_height: 0,
            output_file_type: "png",
            resize_type: "none",
            // converted_image_url: "",
            // converted_image_filename: "",
            resize_width: 0,
            resize_height: 0,
            resize_max_dimension: 0,
            output_quality: 100, //1-100
            output_compression_level: 0, //0-9
            output_progressive: false,
            output_adaptive_filtering: false,
            errors: ""
        }

        //cant use state
        //sending the entire state object to lambda, dont want to send conereted files data, cant remove or copy state
        this.converted_files = []


        //this.state.step = "review";
        // // this.state.step = 3;
        // // this.state.step = 4;
        // this.state.uploaded_image_url = "https://files.convertimages.net/uploads/1587859225293--a3591865-a5d6-a3a5-0198-66bd1f86cf6b.jpg";
        // this.state.uploaded_image_filename = "1587859225293--a3591865-a5d6-a3a5-0198-66bd1f86cf6b.jpg";
        // this.state.output_file_type = "png";
        // //this.state.output_file_type = "ico";
        // this.state.resize_type = "none";
        // this.state.resize_width = 100;
        // this.state.resize_height = 200;
        // this.state.resize_max_dimension = 350;
        // this.state.converted_image_url = "https://files.convertimages.net/downloads/1587944075975--123423ce-36b9-47d2-bfa1-d3bd91e82cd5.png";
        // //this.state.output_quality = 50;
        // //this.state.output_compression_level = 5;
        // this.state.output_progressive = true;
        // // this.state.output_adaptive_filtering = false;

        // this.converted_files = [
        //     {
        //         converted_image_filename: "1587944040895--8555eaf1-2a18-4288-9faf-f19fdab529e6.png",
        //         converted_image_url: "https://files.convertimages.net/downloads/1587944040895--8555eaf1-2a18-4288-9faf-f19fdab529e6.png"
        //     },
        //     {
        //         converted_image_filename: "convertimages.net--250x144--58de78a8-464a-4306-bc10-39e0587f8a74.png",
        //         converted_image_url: "https://files.convertimages.net/downloads/convertimages.net--250x144--58de78a8-464a-4306-bc10-39e0587f8a74.png"
        //     },
        //     {
        //         converted_image_filename: "convertimages.net--1c722674-6c20-4244-b47e-171757f64b08.png",
        //         converted_image_url: "https://files.convertimages.net/downloads/convertimages.net--1c722674-6c20-4244-b47e-171757f64b08.png"
        //     },
        //     {
        //         converted_image_filename: "convertimages.net--6250e07b-02bb-47d8-a5a1-943777971683.png",
        //         converted_image_url: "https://files.convertimages.net/downloads/convertimages.net--6250e07b-02bb-47d8-a5a1-943777971683.png"
        //     }
        // ];


        //functions
        this.onChange = this.onChange.bind(this);
        this.onChange_slider_compression_level = this.onChange_slider_compression_level.bind(this);
        this.onChange_slider_quality = this.onChange_slider_quality.bind(this);
        this.onChange__file_input = this.onChange__file_input.bind(this);
        this.onClick__set_dimensions = this.onClick__set_dimensions.bind(this);
        this.onClick__process = this.onClick__process.bind(this);

        this.onDragOver = this.onDragOver.bind(this);
        this.onDragDrop = this.onDragDrop.bind(this);


        //timers
        this.timer_get_uploaded_image_details = null;
        this.timer_get_uploaded_image_details_interval = 1000;
        this.timer_get_uploaded_image_details_tick = this.timer_get_uploaded_image_details_tick.bind(this);


        this.timer_errors = null;
        this.timer_errors_interval = 3000;
        this.timer_errors_tick = this.timer_errors_tick.bind(this);


        //aws setup
        AWS.config.region = SiteVariables.aws__region; // Region
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: SiteVariables.aws__cognito_pool_id,
        });

        this.aws_s3 = new AWS.S3();
        this.aws_lambda = new AWS.Lambda();
    }
    componentDidMount()
    {
        //window.addEventListener('mouseup', this._onDragLeave);
        //window.addEventListener('dragenter', this._onDragEnter);
        window.addEventListener('dragover', this.onDragOver);
        window.addEventListener('drop', this.onDragDrop);
        //document.getElementById('dragbox').addEventListener('dragleave', this._onDragLeave);

        var script_ads = document.createElement("script");

        if (cv_rnd_01 === 0)
        {
            script_ads.src = "//pl15420572.passtechusa.com/e051b29361f2ae78a4c93f0e9c0cf22a/invoke.js";
            script_ads.async = true;
        }
        else
        {
            script_ads.src = "//resources.infolinks.com/js/infolinks_main.js";                   
        }

        document.body.appendChild(script_ads);
    }
    componentWillUnmount()
    {
        if (this.timer_get_uploaded_image_details != null)
        {
            clearInterval(this.timer_get_uploaded_image_details);
        }
        if (this.timer_errors != null)
        {
            clearInterval(this.timer_errors);
        }
    }
    function__set_errors(msg)
    {
        this.setState({
            errors: msg
        });

        //start timer to get image details
        this.timer_errors = setInterval(
            () => this.timer_errors_tick(),
            this.timer_errors_interval
        );
    }
    timer_errors_tick()
    {
        this.setState({
            errors: ""
        });
        if (this.timer_errors != null)
        {
            clearInterval(this.timer_errors);
        }
    }
    onDragOver(e)
    {
        e.preventDefault();
        //console.log("onDragOver");
    }
    onDragDrop(e)
    {
        e.preventDefault();

        //console.log("onDragDrop");
        //console.log(e);
        //console.log(e.toElement);
        //console.log(e.toElement.id);
        //console.log(e.target.id);
        //console.log(e.dataTransfer.files);

        var target_id = e.toElement.id;

        if (target_id === "file-upload-drag-drop" || target_id === "file-upload-drag-drop-text")
        {
            if (e !== null && e.dataTransfer !== null && e.dataTransfer.files !== null && e.dataTransfer.files.length > 0)
            {
                //console.log(e.dataTransfer.files.length);
                var file = e.dataTransfer.files[0];
                this.function__file_upload(file)
            }
        }
    }
    onChange_slider_compression_level(e, value)
    {
        // console.log(e);
        // console.log(e.target);
        // console.log(e.target.name);
        // console.log(value);

        this.setState({
            output_compression_level: value
        })
    }
    onChange_slider_quality(e, value)
    {
        // console.log(e);
        // console.log(e.target);
        // console.log(e.target.name);
        // console.log(value);

        this.setState({
            output_quality: value
        });
    }
    onChange(e)
    {
        // console.log(e);
        // console.log(e.target);
        // console.log(e.target.value);
        // console.log(e.target.name);        

        var set_value = true;
        var value = e.target.value;
        if (e.target.name === "resize_width"
            || e.target.name === "resize_width"
            || e.target.name === "resize_max_dimension"
        )
        {
            //ensure its a number
            if (isNaN(value))
            {
                set_value = false;
            }
            else
            {
                value = parseInt(value, 10);
            }
        }


        if (set_value === true)
        {
            var set_resize_type = false;

            //check if we should change the resize type
            var current_resize_type = this.state.resize_type;
            //var resize_type = e.target.dataset.resize_type;
            var resize_type = e.target.getAttribute("data-resize-type");
            //console.log("resize type: " + resize_type);

            if (resize_type !== null && resize_type !== undefined && resize_type !== "")
            {
                if (resize_type !== current_resize_type)
                {
                    set_resize_type = true;
                    current_resize_type = resize_type;
                }
            }

            if (set_resize_type === true)
            {
                // console.log("change resize type");
                // console.log("new type: " + current_resize_type);

                this.setState({
                    [e.target.name]: value,
                    resize_type: current_resize_type
                })
            }
            else
            {
                this.setState({
                    [e.target.name]: value
                })
            }
        }
    }
    onChange__file_input(e)
    {
        // console.log("onChange__file_input");
        // console.log(e);


        if (e.currentTarget.files == null
            || e.currentTarget.files.length !== 1)
        {
            return;
        }


        var file = e.currentTarget.files[0];
        if (file == null)
        {
            this.function__set_errors("File not found.");
            return;
        }


        this.function__file_upload(file);
    }
    function__file_upload(file)
    {
        //console.log("function__file_upload");

        var file_size = 0;
        if (file.size != null)
        {
            file_size = file.size;
        }
        if (file_size > SiteVariables.aws__s3__max_file_size)
        {
            this.function__set_errors("File too big.");
            return;
        }


        var original_filename = file.name.toString().toLowerCase();
        var original_filename_parts = original_filename.split(".");
        var extension = original_filename_parts[original_filename_parts.length - 1];


        if (
            extension !== "jpg"
            && extension !== "jpeg"
            && extension !== "gif"
            && extension !== "png"
            && extension !== "bpm"
        )
        {
            this.function__set_errors("Invalid file type.");
            return;
        }


        this.setState({
            step: "uploading"
        });


        //show progress meter
        $("#profile-header-image-file-upload-progress").show();



        //new file name ex: 2019-01-01--12-01-01--123.jpg";
        //using this.new_filename since we need this after the s3 call
        this.new_filename = ""
        this.new_filename += Date.now();
        this.new_filename += "--";
        this.new_filename += function__misc__get_guid();
        this.new_filename += "." + extension;



        //new upload key
        var new_key = "";
        new_key += "user-files/uploads/";
        new_key += this.new_filename;



        //meta data values must be strings
        var uploadParams = {
            Bucket: SiteVariables.aws__s3__uploads_bucket,
            Key: new_key,
            Body: file,
            // Metadata: {
            //     "x-amz-meta-user-id": this.state.user.Id.toString()
            // },
        };
        //clog(uploadParams);        


        var self = this;

        //upload new file to s3
        this.aws_s3.upload(uploadParams, function (err, data)
        {
            //clog(err);
            //clog(data);

            //$("#profile-header-image-file-upload-progress").hide();

            if (err)
            {
                //function__errors__log_error("AWS Profile Header Upload", err);
                self.function__set_errors("There was an error uploading your file.");
                console.log(err);
                return;
            }
            else
            {
                //success
                //console.log(data);

                //success - update db                
                var tmp_url = SiteVariables.urls__user_files_uploads_base + self.new_filename;
                //console.log("tmp_url: " + tmp_url);

                self.setState({
                    uploaded_image_url: tmp_url,
                    uploaded_image_filename: self.new_filename,
                    step: "review"
                });


                //start timer to get image details
                self.timer_get_uploaded_image_details = setInterval(
                    () => self.timer_get_uploaded_image_details_tick(),
                    self.timer_get_uploaded_image_details_interval
                );
            }
        })
            .on('httpUploadProgress', function (evt)
            {
                try
                {
                    var percent_complete = evt.loaded / evt.total;
                    percent_complete = percent_complete * 100;

                    //clog('Progress:', evt.loaded, '/', evt.total);
                    //clog(percent_complete);

                    $("#profile-header-image-file-upload-progress div").css("width", percent_complete.toString() + "%");
                }
                catch (err)
                {
                    console.log(err);
                    //function__errors__log_error(err);
                }
            });
    }
    timer_get_uploaded_image_details_tick()
    {
        //console.log("timer_get_uploaded_image_details_tick");

        try
        {
            var uploaded_image = document.querySelector("#uploaded-image");
            var width = uploaded_image.naturalWidth;
            var height = uploaded_image.naturalHeight;

            // console.log(uploaded_image);
            // console.log(width);
            // console.log(height);

            if (width > 0 && height > 0)
            {
                this.setState({
                    uploaded_image_width: width,
                    uploaded_image_height: height
                })

                clearInterval(this.timer_get_uploaded_image_details);
            }
        }
        catch
        { }
    }
    onClick__set_dimensions(e)
    {
        //console.log(e);
        e.preventDefault();

        var width = e.target.dataset.width;
        var height = e.target.dataset.height;
        var multiple = e.target.dataset.multiple;

        if (multiple !== null && multiple > 0)
        {
            width = this.state.uploaded_image_width * multiple;
            height = this.state.uploaded_image_height * multiple;

            width = parseInt(width, 10);
            height = parseInt(height, 10);
        }

        this.setState({
            resize_width: width,
            resize_height: height,
            resize_type: "exact-dimensions"
        })
    }
    onClick__process(e)
    {
        this.setState({
            step: "processing"
        });


        //defaults
        var payload = this.state;
        //state is immutable, this wont work    
        // if (payload.output_file_type === "ico")
        // {
        //     exact-dimensions
        // }

        var payload_string = JSON.stringify(payload)
        var params = {
            FunctionName: 'convert-images--convert',
            Payload: payload_string
        };


        var self = this;
        this.aws_lambda.invoke(params, function (err, data)
        {
            if (err)
            {
                console.log(err, err.stack); // an error occurred
                self.function__set_errors("An unexpected error has occurred.");
            }
            else
            {
                // console.log(data);           // successful response
                // console.log(data.Payload);

                var payload = JSON.parse(data.Payload);
                //console.log(payload);

                if (payload !== null && payload.converted_image_filename !== null && payload.converted_image_filename !== "")
                {
                    payload.converted_image_url = SiteVariables.urls__user_files_downloads_base + payload.converted_image_filename;

                    var converted_files = self.converted_files;
                    converted_files.push(payload);

                    self.converted_files = converted_files;

                    self.setState({
                        //converted_image_filename: new_filename,
                        //converted_image_url: SiteVariables.urls__user_files_downloads_base + new_filename,                        
                        step: "review"
                    });


                    //scroll to the processed images
                    $('html, body').animate({
                        scrollTop: ($('#converted-images').offset().top)
                    }, 500);
                }
            }
        });
    }
    render()
    {
        //console.log(this.state);

        //testing
        // if (this.timer_get_uploaded_image_details == null)
        // {
        //     this.timer_get_uploaded_image_details = setInterval(
        //         () => this.timer_get_uploaded_image_details_tick(),
        //         this.timer_get_uploaded_image_details_interval
        //     );
        // }        


        const converted_items = [];
        if (this.converted_files !== null && this.converted_files.length > 0)
        {
            //console.log(this.state.converted_files);
            for (var converted_index = 0; converted_index < this.converted_files.length; converted_index++)
            {
                converted_items.push(
                    <div key={converted_index} className="item">
                        <div className="download">
                            <a target="_blank" rel="noopener noreferrer" href={this.converted_files[converted_index].converted_image_url}>Download</a>
                        </div>
                        <div className="image">
                            <img className="uploaded-image" alt="uploaded" src={this.converted_files[converted_index].converted_image_url} />
                        </div>
                    </div>
                )
            }
        }

        return (
            <div className="container">
                {this.state.errors !== "" &&
                    <div className="error-message">{this.state.errors}</div>
                }


                {(this.state.step === "uploading" || this.state.step === "processing") &&
                    <div className="step processing">
                        <div className="step-details">
                            <div className="spinner"></div>
                        </div>
                    </div>
                }
                {this.state.step === "upload" &&
                    <div className="step upload-new-image">
                        <div className="step-number">Upload Image</div>
                        <div className="step-details">
                            <div className="file-selector">
                                <label htmlFor="file">
                                    <i className="fas fa-file-upload"></i>
                                    <input id="file" type="file" name="file" onChange={this.onChange__file_input} />
                                    <span>Select an Image…</span>
                                </label>
                            </div>
                            <div id="file-upload-drag-drop" className="drag-drop-file">
                                <span id="file-upload-drag-drop-text">Drag & Drop Image</span>
                            </div>
                            <div id="profile-header-image-file-upload-progress" className="form-group upload-progress">
                                <div></div>
                            </div>
                        </div>
                    </div>
                }
                {this.state.step === "uploading" &&
                    <div className="step uploading">
                        <div className="step-details">
                            <div id="profile-header-image-file-upload-progress" className="form-group upload-progress">
                                <div></div>
                            </div>
                        </div>
                    </div>
                }
                {this.state.step === "review" &&
                    <div className="step uploaded-image">
                        <div className="step-number">Your Image</div>
                        <div className="step-details">
                            <img id="uploaded-image" className="uploaded-image" alt="uploaded" src={this.state.uploaded_image_url} />
                            <div className="image-details">
                                {this.state.uploaded_image_width > 0 &&
                                    <div>{this.state.uploaded_image_width}x{this.state.uploaded_image_height}</div>
                                }
                            </div>
                        </div>
                    </div>
                }
                {this.state.step === "review" &&
                    <div className="step output-file-type">
                        <div className="step-number">Output File Type</div>
                        <div className="step-details">
                            <div className="form-group">
                                <select className="form-control" name="output_file_type" value={this.state.output_file_type} onChange={this.onChange} >
                                    <option value="png">PNG</option>
                                    <option value="jpg">JPEG</option>
                                    <option value="webp">WEBP</option>
                                    <option value="tiff">TIFF</option>
                                    <option value="ico">ICO</option>
                                </select>
                            </div>
                        </div>
                    </div>
                }
                {this.state.step === "review" && this.state.output_file_type === "png" &&
                    <div className="step output-compression-level">
                        <div className="step-number">Compression</div>
                        <div className="step-details">
                            <div className="form-group">
                                <Slider
                                    name="output_compression_level"
                                    min={0}
                                    max={9}
                                    value={this.state.output_compression_level}
                                    onChange={this.onChange_slider_compression_level}
                                />
                            </div>
                        </div>
                    </div>
                }
                {this.state.step === "review" && (this.state.output_file_type === "jpg" || this.state.output_file_type === "webp" || this.state.output_file_type === "tiff") &&
                    <div className="step output-compression-level">
                        <div className="step-number">Quality</div>
                        <div className="step-details">
                            <div className="form-group">
                                <Slider
                                    name="output_compression_level"
                                    min={1}
                                    max={100}
                                    value={this.state.output_quality}
                                    onChange={this.onChange_slider_quality}
                                />
                            </div>
                        </div>
                    </div>
                }
                {this.state.step === "review" &&
                    <div className="step resize-options">
                        <div className="step-number">Resize Options</div>
                        <div className="step-details">
                            <div className="row">
                                {this.state.output_file_type !== "ico" &&
                                    <div className="col-12 col-md-4">
                                        <div className="resize-option">
                                            <label>
                                                <input type="radio" name="resize_type" value="none"
                                                    onChange={this.onChange}
                                                    checked={this.state.resize_type === "none"}
                                                />
                                    None
                                    </label>
                                            <div className="description">
                                                <ul>
                                                    <li>Keep the image the same size.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {this.state.output_file_type !== "ico" &&
                                    <div className="col-12 col-md-4">
                                        <div className="resize-option">
                                            <label>
                                                <input type="radio" name="resize_type" value="max-dimension"
                                                    onChange={this.onChange}
                                                    checked={this.state.resize_type === "max-dimension"}
                                                />
                                    Max Dimension
                                    </label>
                                            <div className="description">
                                                <ul>
                                                    <li>Ensure neither the width nor the height exceeds the max dimension.</li>
                                                    <li>Maintains the current aspect ratio.</li>
                                                </ul>
                                            </div>
                                            <div className="form-group">
                                                <label>Max Dimensions</label>
                                                <input className="form-control" data-resize-type="max-dimension" type="text" name="resize_max_dimension" value={this.state.resize_max_dimension} onChange={this.onChange} />
                                            </div>
                                        </div>
                                    </div>
                                }
                                {this.state.output_file_type !== "ico" &&
                                    <div className="col-12 col-md-4">
                                        <div className="resize-option">
                                            <label>
                                                <input type="radio" name="resize_type" value="exact-dimensions"
                                                    onChange={this.onChange}
                                                    checked={this.state.resize_type === "exact-dimensions"}
                                                />
                                    Exact Dimensions
                                    </label>
                                            <div className="description">
                                                <ul>
                                                    <li>Set the exact dimensions you want.</li>
                                                    <li>Does not maintain aspect ratio.</li>
                                                </ul>
                                            </div>
                                            {this.state.uploaded_image_width > 0 &&
                                                <div className="multiple-dimensions">
                                                    <div className="form-group">
                                                        <label>Multiples:</label>
                                                        <div className="form-group">
                                                            <button onClick={this.onClick__set_dimensions} data-multiple=".1">10%</button>
                                                            <button onClick={this.onClick__set_dimensions} data-multiple=".25">25%</button>
                                                            <button onClick={this.onClick__set_dimensions} data-multiple=".5">50%</button>
                                                            <button onClick={this.onClick__set_dimensions} data-multiple=".75">75%</button>
                                                            <button onClick={this.onClick__set_dimensions} data-multiple="2">200%</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            <div>
                                                <div className="common-dimensions">
                                                    <div className="form-group">
                                                        <label>Common Dimensions:</label>
                                                        <div className="form-group">
                                                            <button onClick={this.onClick__set_dimensions} data-width="16" data-height="16">16x16</button>
                                                            <button onClick={this.onClick__set_dimensions} data-width="32" data-height="32">32x32</button>
                                                            <button onClick={this.onClick__set_dimensions} data-width="48" data-height="48">48x48</button>
                                                            <button onClick={this.onClick__set_dimensions} data-width="64" data-height="64">64x64</button>
                                                            <button onClick={this.onClick__set_dimensions} data-width="96" data-height="96">96x96</button>
                                                            <button onClick={this.onClick__set_dimensions} data-width="128" data-height="128">128x128</button>
                                                            <button onClick={this.onClick__set_dimensions} data-width="1920" data-height="1080">1920x1080</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group extact-dimensions">
                                                    <label>Dimensions:</label>
                                                    <input className="form-control" data-resize-type="exact-dimensions" type="text" name="resize_width" value={this.state.resize_width} onChange={this.onChange} />
                                            x
                                            <input className="form-control" data-resize-type="exact-dimensions" type="text" name="resize_height" value={this.state.resize_height} onChange={this.onChange} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }

                                {this.state.output_file_type === "ico" &&
                                    <div className="col-12 col-md-4">
                                        <div className="resize-option">
                                            <label>
                                                Dimensions
                                            </label>
                                            <div className="description">
                                                <ul>
                                                    <li>Select your output size.</li>
                                                </ul>
                                            </div>
                                            <div>
                                                <div className="common-dimensions">
                                                    <div className="form-group">
                                                        <label>Common Dimensions:</label>
                                                        <div className="form-group">
                                                            <button onClick={this.onClick__set_dimensions} data-width="16" data-height="16">16x16</button>
                                                            <button onClick={this.onClick__set_dimensions} data-width="24" data-height="24">24x24</button>
                                                            <button onClick={this.onClick__set_dimensions} data-width="32" data-height="32">32x32</button>
                                                            <button onClick={this.onClick__set_dimensions} data-width="48" data-height="48">48x48</button>
                                                            <button onClick={this.onClick__set_dimensions} data-width="64" data-height="64">64x64</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group extact-dimensions">
                                                    <label>Dimensions:</label>
                                                    <input disabled className="form-control" data-resize-type="exact-dimensions" type="text" name="resize_width" value={this.state.resize_width} onChange={this.onChange} />
                                            x
                                            <input disabled className="form-control" data-resize-type="exact-dimensions" type="text" name="resize_height" value={this.state.resize_height} onChange={this.onChange} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }

                            </div>
                        </div>
                    </div>
                }
                {this.state.step === "review" &&
                    <div className="step process">
                        <div className="step-number">Process</div>
                        <div className="step-details">
                            <label>
                                <button onClick={this.onClick__process}>Process</button>
                            </label>
                        </div>
                    </div>
                }
                {this.state.step === "review" && converted_items.length > 0 &&
                    <div className="step success" id="converted-images">
                        <div className="step-number">Processed Files</div>
                        <div className="step-details">
                            {converted_items}
                        </div>
                    </div>
                }
            </div>
        )
    }
}